@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('react-datepicker/dist/react-datepicker.css');
@import url('react-big-calendar/lib/css/react-big-calendar.css');
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;700&display=swap');
body {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.page-title {
    @apply text-3xl font-medium text-center py-10 mb-6 text-gray-700 after:block after:absolute after:w-28 after:h-1 after:bottom-7 after:bg-emerald-600 relative after:left-1/2 after:-translate-x-1/2;
}
