.organizations-carousel {
    &.swiper {
        .swiper-pagination {
            .swiper-pagination-bullet {
                &.swiper-pagination-bullet-active {
                    background-color: #059669;
                }
            }
        }
    }
}
