.react-datepicker {
    &__day--selected {
        @apply bg-emerald-600 hover:bg-emerald-700;
    }
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    @apply bg-emerald-600 hover:bg-emerald-700;
}
