.home-carousel {
    &.swiper {
        width: 100%;
        height: 100%;
        .swiper-button-next,
        .swiper-button-prev {
            background-repeat: no-repeat;
            background-size: 50% auto;
            background-position: center;
            padding: 1.7rem;
            border-radius: 1.7rem;
            background-color: #ffffff3b;
            transition: all 0.3s;
            &:hover {
                background-color: #059669;
                transition: all 0.3s;
            }
            &::after {
                display: none;
            }
        }
        .swiper-button-next {
            background-image: url('../../../assets/svg/arrow-right.svg');
            right: 2rem;
        }

        .swiper-button-prev {
            background-image: url('../../../assets/svg/arrow-left.svg');
            left: 2rem;
        }
    }
}
